import View from './View';

class customRecipeView extends View {
  _parentEl = document.querySelector('.upload');
  _message = 'Recipe was succecsfully uploaded';
  _closeWindowBtn = document.querySelector('.btn--close-modal');
  _addRecipeBtn = document.querySelector('.nav__btn--add-recipe');
  _overlay = document.querySelector('.overlay');
  _form = document.querySelector('.add-recipe-window');

  constructor() {
    super();
    this._addRecipeHandler();
    this._closeWindow();
  }

  _toggleWindow() {
    this._overlay.classList.toggle('hidden');
    this._form.classList.toggle('hidden');
  }

  _addRecipeHandler() {
    this._addRecipeBtn.addEventListener('click', this._toggleWindow.bind(this));
  }

  _closeWindow() {
    this._closeWindowBtn.addEventListener(
      'click',
      this._toggleWindow.bind(this)
    );
    this._overlay.addEventListener('click', this._toggleWindow.bind(this));
  }

  addHandlerUpload(handler) {
    this._parentEl.addEventListener('submit', function (e) {
      e.preventDefault();
      const data = [...new FormData(this)];
      //Turns Array into object like with the use of a key-value pair structure
      const dataObj = Object.fromEntries(data);
      console.log(dataObj);
      handler(dataObj);
    });
  }
}

export default new customRecipeView();
