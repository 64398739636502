import View from './View.js';
import icons from '../../img/icons.svg'; //Parcel 2

class PaginationView extends View {
  _parentEl = document.querySelector('.pagination');

  addButtonHandler(handler) {
    this._parentEl.addEventListener('click', function (e) {
      e.preventDefault();
      const btn = e.target.closest('.btn--inline');
      if (!btn) return;
      const goToPage = +btn.dataset.goto;
      console.log(goToPage);
      handler(goToPage);
    });
  }
  _generateMarkup() {
    const numPages = Math.ceil(
      this._data.results.length / this._data.resultsPerPage // [] = 59 Elements  / 10 = resultsPerPage = 6
    ); // numPages = 6

    const curPage = this._data.page;
    // Page 1 , there are other pages
    // if page is 1 we only want the next button to be shown
    if (curPage === 1 && numPages > 1) {
      return `<button data-goTo="${
        curPage + 1
      }" class="btn--inline pagination__btn--next">
                <span>Page ${curPage + 1}</span>
                <svg class="search__icon">
                  <use href="${icons}#icon-arrow-right"></use>
                </svg>
              </button>`;
    }

    // Other Page
    // if the page value is between first page and last page both next and prev button should be shown
    if (curPage < numPages) {
      return `<button data-goTo="${
        curPage + 1
      }" class="btn--inline pagination__btn--next">
                <span>Page ${curPage + 1}</span>
                <svg class="search__icon">
                  <use href="${icons}#icon-arrow-right"></use>
                </svg>
              </button>
              <button data-goTo="${
                curPage - 1
              }"class="btn--inline pagination__btn--prev">
                <svg class="search__icon">
                  <use href="${icons}#icon-arrow-left"></use>
                </svg>
                <span>Page ${curPage - 1}</span>
              </button>
              `;
    }

    //Last Page
    //if the page is the last page only prev button is shown
    if (curPage === numPages && numPages > 1) {
      return `<button data-goTo="${
        curPage - 1
      }"class="btn--inline pagination__btn--prev">
              <svg class="search__icon">
                <use href="${icons}#icon-arrow-left"></use>
              </svg>
              <span>Page ${curPage - 1}</span>
            </button>`;
    }
    // Page 1 , and there are no other pages
    // If numpages value is only 1 we dont want any buttons to be shown

    return '';
  }
}

export default new PaginationView();
