import { TIMEOUT_SEC } from './config';

const timeout = function (s) {
  return new Promise(function (_, reject) {
    setTimeout(function () {
      reject(new Error(`Request took too long! Timeout after ${s} second`));
    }, s * 1000);
  });
};
export const AJAX = async function (url, uploadData = undefined) {
  try {
    const fetchPro = uploadData
      ? fetch(url, {
          //POST for sending data
          method: 'POST',
          //Headers are information about the request
          headers: {
            //here were indicating to the API that were sending a JSON format
            'Content-type': 'application/json',
          },
          // The data that will be posted in the API
          body: JSON.stringify(uploadData),
        })
      : fetch(url);

    const res = await Promise.race([fetchPro, timeout(TIMEOUT_SEC)]);
    const data = await res.json();
    if (!res.ok) throw new Error(`${data.message} (${res.status})`);
    return data;
  } catch (err) {
    throw err;
  }
};
export const getJSON = async function (url) {
  try {
    const res = await Promise.race([fetch(url), timeout(TIMEOUT_SEC)]);
    const data = await res.json();
    if (!res.ok) throw new Error(`${data.message} (${res.status})`);
    return data;
  } catch (err) {
    throw err;
  }
};

export const sendJSON = async function (url, uploadData) {
  try {
    /*
    sending a data using fetch function
    -- 1st parameter is the url 
    -- 2nd parameter is the options for the POST method
    */
    const fetchPOST = fetch(url, {
      //POST for sending data
      method: 'POST',
      //Headers are information about the request
      headers: {
        //here were indicating to the API that were sending a JSON format
        'Content-type': 'application/json',
      },
      // The data that will be posted in the API
      body: JSON.stringify(uploadData),
    });
    //retrieving the custom recipe from the API
    const res = await Promise.race([fetchPOST, timeout(TIMEOUT_SEC)]);
    const data = await res.json();
    if (!res.ok) throw new Error(`${data.message} (${res.status})`);
    return data;
  } catch (err) {
    throw err;
  }
};
