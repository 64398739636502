import { __esModule } from 'wow.js';
import * as model from './model.js';
import recipeView from './views/recipeViews.js';
import resultsView from './views/resultsView.js';
import searchView from './views/searchView.js';
import paginationView from './views/paginationView.js';
import bookmarkView from './views/bookmarkView.js';
import customRecipeView from './views/customRecipeView.js';
import { CLOSE_WINDOW } from './config.js';

//allows us to maintain the state of the app when it reloads
// if (module.hot) {
//   module.hot.accept();
// }

const controlRecipe = async function () {
  try {
    const id = window.location.hash.slice(1);
    if (!id) return;

    // Rendering Spinner
    recipeView.renderSpinner();
    //Load Bookmars
    //0. Update results view UI to highligh selected search result
    resultsView.update(model.getSearchResultPage());
    //0.1 Update the bookmarks View
    bookmarkView.update(model.state.bookmarks);

    //1. Loading the API
    await model.loadRecipe(id);
    //2. Rendering the markup
    recipeView.render(model.state.recipe);
  } catch (err) {
    recipeView.renderError();
  }
};

const controlSearchResult = async function () {
  try {
    //rendering spinner
    resultsView.renderSpinner();
    //1. Getting the search query
    const query = searchView.getQuery();
    if (!query) return;
    //2 . Loading the search result
    await model.loadSearchResult(query);
    //3. Rendering the search result in the UI
    resultsView.render(model.getSearchResultPage());
    //4. Rendering Buttons
    paginationView.render(model.state.search);
  } catch (err) {
    console.log(err);
  }
};

const controlPagination = function (goToPage) {
  //3. Rendering the NEW search result in the UI
  resultsView.render(model.getSearchResultPage(goToPage));
  //4. Rendering the New Buttons
  paginationView.render(model.state.search);
};

const controlServings = function (servings) {
  //1. Update the recipe servings
  model.updateServings(servings);
  //2. Re-render the recipe view
  recipeView.update(model.state.recipe);
};

//Adding Bookmark
const controlAddBookmark = function () {
  //1. Add Bookmarks
  if (!model.state.recipe.bookmarked) {
    model.addBookmark(model.state.recipe);
  } else {
    model.deleteBookmark(model.state.recipe.id);
  }
  //2 Update Recipeview changes
  recipeView.update(model.state.recipe);
  //3. Render Bookmark from the bookmark tab
  bookmarkView.render(model.state.bookmarks);
};

// Loading the bookmarks from the storage as soon as the page loadsz
const loadBookmarks = function () {
  bookmarkView.render(model.state.bookmarks);
};

// Adding Custom Recipes
const controlAddRecipe = async function (newRecipe) {
  try {
    //1. Upload Recipe to the API
    await model.uploadCustomRecipe(newRecipe);
    //2. Render Custom Recipe to the recipeView
    recipeView.render(model.state.recipe);
    //3, Render success message
    customRecipeView.renderMessage();
    //4. Re-render bookmarks
    bookmarkView.render(model.state.bookmarks);
    //5. Change ID  in url
    window.history.pushState(null, '', `#${model.state.recipe.id}`);
    //4. Close form window
    setTimeout(function () {
      customRecipeView._toggleWindow();
    }, CLOSE_WINDOW * 1000);
  } catch (err) {
    console.error(err);
    customRecipeView.renderError(err.message);
  }
};
const init = function () {
  bookmarkView.loadBookmarkHandler(loadBookmarks);
  recipeView.addHandlerRender(controlRecipe);
  searchView.addSearchHandler(controlSearchResult);
  paginationView.addButtonHandler(controlPagination);
  recipeView.updateServingsHandler(controlServings);
  recipeView.addBookmarkHandler(controlAddBookmark);
  customRecipeView.addHandlerUpload(controlAddRecipe);
};

init();
// ['hashchange', 'load'].forEach(event =>
//   window.addEventListener(event, controllerRecipes)
// );
// window.addEventListener('hashchange', controllerRecipes);
// window.addEventListener('load', showRecipe);
