import { API_URL, KEY, RESULTS_PER_PAGE } from './config';
import { AJAX } from './helper';

export const state = {
  recipe: {},
  search: {
    query: '',
    results: [],
    page: 1,
    resultsPerPage: RESULTS_PER_PAGE,
  },
  bookmarks: [],
  getBookmarkList: JSON.parse(window.localStorage.getItem('bookmarkList')),
};
const createRecipeObject = function (data) {
  const { recipe } = data.data;
  return {
    id: recipe.id,
    title: recipe.title,
    publisher: recipe.publisher,
    sourceURL: recipe.source_url,
    image: recipe.image_url,
    servings: recipe.servings,
    cookingTime: recipe.cooking_time,
    ingredients: recipe.ingredients,
    //Using short circuiting to set a key in the recipe if it exists in the data
    ...(recipe.key && { key: recipe.key }),
  };
};

export const loadRecipe = async function (id) {
  try {
    //1.Getting the recipe
    const data = await AJAX(`${API_URL}${id}?key=${KEY}`);
    //2. Creating the recipe state
    state.recipe = createRecipeObject(data);

    if (state.bookmarks.some(bookmark => bookmark.id === id))
      state.recipe.bookmarked = true;
    else state.recipe.bookmarked = false;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const loadSearchResult = async function (query) {
  try {
    //1. Setting the query to the state
    state.search.query = query;

    //2, fetching search results from the API
    const data = await AJAX(`${API_URL}?search=${query}&key=${KEY}`);

    state.search.results = data.data.recipes.map(recipe => {
      return {
        id: recipe.id,
        title: recipe.title,
        publisher: recipe.publisher,
        image: recipe.image_url,
        ...(recipe.key && { key: recipe.key }),
      };
    });

    //Resetting the page value when a new search is queried
    state.search.page = 1;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getSearchResultPage = function (page = state.search.page) {
  state.search.page = page;
  const start = (page - 1) * state.search.resultsPerPage; // page 1 = ( page - 1 ) * 10 = 0
  const end = page * state.search.resultsPerPage; // end of page =  1 * 10 = 10 = 10
  return state.search.results.slice(start, end); //[ ] = array with the length of 10 items
};

export const updateServings = function (newServings) {
  state.recipe.ingredients.forEach(ing => {
    ing.quantity = (ing.quantity * newServings) / state.recipe.servings;
    // ing.quantity = oldQuantity * newServings  / oldServings
  });
  state.recipe.servings = newServings;
};

const updateBookmarkLocalStorage = function () {
  window.localStorage.setItem('bookmarkList', JSON.stringify(state.bookmarks));
};
//Adding a bookmark on a recipe - Jonas Version
export const addBookmark = function (recipe) {
  //Adding bookmark
  state.bookmarks.push(recipe);
  //Marking current as bookmarked
  if (recipe.id === state.recipe.id) state.recipe.bookmarked = true;
  updateBookmarkLocalStorage();
};

//Deleting a bookmark from a recipe page
export const deleteBookmark = function (id) {
  //Delete Bookmark
  const index = state.bookmarks.findIndex(el => el.id == id);
  state.bookmarks.splice(index, 1);
  //Changing the bookmark value of the current recipe to false
  if (id === state.recipe.id) state.recipe.bookmarked = false;
  updateBookmarkLocalStorage();
};

export const updateBookmarkStorage = function () {
  //Update the bookmark Storage
  window.localStorage.setItem('bookmarkList', JSON.stringify(state.bookmarks));
};

export const uploadCustomRecipe = async function (newRecipe) {
  /*
    1. Setting the ingredients data
     -- Converting the object into array to be able to loop with them 
     -- using filter to select the array element with the first array element of an 'ingredient' string and the second array element with value and not an empty string
     -- mapping the filtered array , removing spaces and splitting the string if theres a comma so we can get three values
     -- adding a guard clause if the user enters the wrong format and throwing an error . 
     -- destructring the ingArr and using that to create and return an object similar to the API format of ingredients
     -- 
  */

  const ingredients = Object.entries(newRecipe)
    .filter(entry => entry[0].startsWith('ingredient') && entry[1] !== '')
    .map(ing => {
      // with this method were just removing space from string and splitting the string wtih the commas
      const ingArr = ing[1].split(',').map(el => el.trim());
      // if the arr lenght is not equalts to 3 means theres missing inputs and we want to display error
      if (ingArr.length !== 3)
        throw new Error(
          'Wrong ingredient Format . Please use the correct format :)'
        );
      const [quantity, unit, description] = ingArr;

      return { quantity: quantity ? +quantity : null, unit, description };
    });

  //2 . Creating the recipe object
  const recipe = {
    id: newRecipe.id,
    title: newRecipe.title,
    publisher: newRecipe.publisher,
    source_url: newRecipe.sourceUrl,
    image_url: newRecipe.image,
    servings: +newRecipe.servings,
    cooking_time: +newRecipe.cookingTime,
    ingredients,
  };
  //3. Uploading the object to the API
  const data = await AJAX(`${API_URL}?key=${KEY}`, recipe);
  //4. Storing the uploaded recipe to the state and bookmarks
  state.recipe = createRecipeObject(data);
  addBookmark(state.recipe);
};
const init = function () {
  const storage = localStorage.getItem('bookmarkList');
  if (storage) state.bookmarks = JSON.parse(storage);
};

init();

//Updating the Bookmark List - My Version
// export const updateBookmarkList = async function (recipeID) {
//   try {
//     //1. get the recipe using recipeID
//     const data = await getJSON(
//       `https://forkify-api.herokuapp.com/api/v2/recipes/${recipeID}`
//     );
//     const { recipe } = data.data;
//     let recipeBookmark = {
//       id: recipe.id,
//       title: recipe.title,
//       publisher: recipe.publisher,
//       sourceURL: recipe.source_url,
//       image: recipe.image_url,
//       servings: recipe.servings,
//       cookingTime: recipe.cooking_time,
//       ingredients: recipe.ingredients,
//     };
//     //2. Store the recipe to the localstorage
//     const storage = JSON.parse(window.localStorage.getItem('bookmarkList'));
//     if (storage && storage.find(el => el.id == recipeBookmark.id)) return;
//     window.localStorage.setItem(
//       'bookmarkList',
//       !storage
//         ? JSON.stringify([recipeBookmark])
//         : JSON.stringify([recipeBookmark, ...storage])
//     );

//     //3. return the data of the bookmarklist in
//   } catch (err) {
//     console.log(err);
//     throw err;
//   }
// };
